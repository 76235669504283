const user = {
  state:{
    userInfo:null,      // 用户信息
    loginMark: false,   // 是否登录
    signInIs:false,     // 是否签到
    signInIntegral:0    // 签到积分
  },
  mutations:{
    SET_USERINFO: (state, userInfo) => {
      // console.log('-------------set--------------')
      // console.log(userInfo)
      state.userInfo = userInfo
      state.loginMark = true
    },
    SET_SIGNINIS: (state, signInIs) => {
      state.signInIs = signInIs
    },
    SET_SIGNININTEGRAL: (state, signInIntegral) => {
      state.signInIntegral = signInIntegral
    },
  }
}

export default user
