import Vue from 'vue'
import Router from 'vue-router'

const routerTitle = process.env.VUE_APP_TITLE;

Vue.use(Router)

export const constantRoutes = [{
  path: '/',
  component: (resolve) => require(['@/views/index'], resolve),
  hidden: true,
  meta:{
    title: routerTitle
  }
}, {
  path: '/article/view',
  component: (resolve) => require(['@/views/article/view'], resolve),
  hidden: true
}, {
  path: '/circle',
  component: (resolve) => require(['@/views/circle/index'], resolve),
  hidden: true
}, {
  path: '/circle/view',
  component: (resolve) => require(['@/views/circle/view'], resolve),
  hidden: true
}, {
  path: '/circle/card',
  component: (resolve) => require(['@/views/circle/card'], resolve),
  hidden: true
}, {
  path: '/pan',
  component: (resolve) => require(['@/views/pan/index'], resolve),
  hidden: true
}, {
  path: '/search',
  component: (resolve) => require(['@/views/search'], resolve),
  hidden: true
}, {
  path: '/user',
  component: (resolve) => require(['@/views/user/index'], resolve),
  hidden: true
}]



const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

const router = createRouter()

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 如果路由元信息中有标题，设置它
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router
