const getters = {
  userInfo: state => state.user.userInfo,
  loginMark: state => state.user.loginMark,
  signInIs: state => state.user.signInIs,
  signInIntegral: state => state.user.signInIntegral,
}
/**
 * 使用说明
 * import { mapGetters } from 'vuex'
 * computed: {
    ...mapGetters([
      'userInfo'
    ])
  }
 */
export default getters
