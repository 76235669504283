import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from '@/store'



import axios from "axios";
Vue.prototype.$http = axios;

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 图片预览
import 'viewerjs/dist/viewer.css'
import viewer from 'v-viewer'
Vue.use(viewer)

// timeago
import VueTimeago from 'vue-timeago'
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'zh-CN', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    'zh-CN': require('date-fns/locale/zh_cn'),
    ja: require('date-fns/locale/ja')
  }
})

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Element, {
  size: 'small'
})

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const toastOptions = {
  position: "top-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
};
Vue.use(Toast, toastOptions);

// import "./assets/autoptimize.css"
import "./assets/common.css"
import "./assets/style.css"
import "./assets/dev/iconfont.css"
import "./assets/dev/iconfont"
import "./assets/circle.css"
import "./assets/font.css"
import "./assets/dev.css"
import "./assets/mobile.css"
import "./assets/reset.scss"


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
